<template>
  <div v-if="!isLoggedIn" class="mt-3">
    <LandingNoLoginCust />
  </div>
</template>

<script>
import LandingNoLoginCust from '@/components/landing/NoLoginCust';

export default {
  name: 'AnuHomecust',
  computed: {
    isLoggedIn() {
      return this.$store.state.oLogin.isLoggedIn;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push('/');
    }
  },
  components: {
    LandingNoLoginCust
  },
}
</script>
