<template>
  <div>
    <h1>{{ $t('homec.t') }}</h1>
    <b-row class="pt-3 pb-5 bg-white">
      <b-col>
        <p class="lead">
          {{ $t('homec.w1', [fNum(Math.floor(res/30)), fNum(loc)]) }}<br>
          {{ $t('homec.w2', [fNum(users)]) }}
        </p>
        <hr class="my-3">
        <span class="clearfix align-items-center">
          <b-link @click="$store.dispatch('showLogin',true)"><b-icon-box-arrow-in-right v-bind="iT" /> {{ $t('homec.w3') }}</b-link>
        </span>
      </b-col>
      <b-col cols="4" sm="3" fluid>
        <b-row class="mx-0 justify-content-end align-items-start">
          <b-img-lazy fluid rounded src="@/assets/people-in-cafe.jpg" class="opacity-5" style="max-width:200px;width:100%;border-radius:10% !important;" />
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mb-5 justify-content-center">
      <b-col cols="*" lg="10" xl="9">
        <b-card class="align-items-md-center flex-md-row" body-class="p-2" no-body>
          <b-card-img-lazy :src="require('@/assets/table.jpg')" :alt="$t('home.ut')" left class="d-none d-md-block" />
          <b-card-img-lazy :src="require('@/assets/table-sm.jpg')" :alt="$t('home.ut')" top class="d-block d-md-none" />
          <b-card-body>
            <b-row class="justify-content-center pb-2">
              <p class="lead">{{ $t('homec.ut') }}</p>
            </b-row>
            <b-row class="justify-content-center pb-3">
              <div class="d-inline-flex flex-column">
                <b-row class="px-0 justify-content-center pb-3 flex-nowrap" v-for="(i, idx) in regSteps" :key="`s`+idx">
                  <b-col cols="1"><b-badge variant="primary" pill style="vertical-align:middle !important;margin-top:-.2em !important;">{{ idx+1 }}</b-badge></b-col>
                  <b-col cols="11">{{ i.t }}</b-col>
                </b-row>
              </div>
            </b-row>
            <b-row class="justify-content-center">
              <b-button variant="primary" class="btn-lg mb-2" type="button" id="lanRegCust" v-b-modal.sgnWindowC @click="showSignup=true">
                <b-icon-card-checklist v-bind="iB" /> {{ $t('homec.ub') }}
              </b-button>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="mb-2">
          {{ $t('homec.st') }}
        </h3>
      </b-col>
    </b-row>
    <b-row class="mb-4 justify-content-center">
      <b-card-group deck class="mx-0 deck-1 deck-sm-2 deck-xl-4 justify-content-center px-5 px-sm-3">
        <b-card v-for="(i, idx) in exeSteps" :key="`e`+idx" class="border-0">
          <b-card-img-lazy :src="i.i()" top :alt="i.a" class="opacity-4" />
          <b-card-text class="text-center">
            <b-badge variant="primary" pill style="vertical-align:middle !important;margin-top:-.2em !important;">{{ idx+1 }}</b-badge> {{ i.t }}
          </b-card-text>
        </b-card>
      </b-card-group>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="mb-2">
          {{ $t('homec.at') }}
        </h3>
      </b-col>
    </b-row>
    <b-card class="mb-4 border-0" align="left" body-class="p-2">
      <b-list-group class="text-justify text-secondary">
        <b-list-group-item v-for="(i, idx) in advantages" :key="`a`+idx" :class="`border-0 pl-3 pr-0 py-1 text-`+(hovered===idx?`primary`:`secondary`)" @mouseover="onHover(idx)" @mouseleave="onUnhover">
          <b-form-row>
            <b-col cols="1" class="text-right pr-4">
              <component :is="i.c" scale="1.5" />
            </b-col>
            <b-col cols="11">
              <b>{{ i.h }}</b>
            </b-col>
          </b-form-row>
          <b-form-row class="justify-content-end">
            <b-col cols="11">
              {{ i.t }}
            </b-col>
          </b-form-row>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <b-row>
      <b-col>
        <h3 class="mb-2">
          {{ $t('homec.ot') }}
        </h3>
      </b-col>
    </b-row>
    <b-carousel class="slide mb-5" id="lanCaro" indicators controls>
      <b-carousel-slide v-for="(i, idx) in userComments" :key="`o`+idx">
        <template #img>
          <b-img alt="Transparent" blank width="150" height="205" class="d-block img-fluid" />
        </template>
        <h5 class="text-secondary">
          {{ i.h }}
        </h5>
        <p class="text-secondary">{{ i.t }}</p>
        <small class="text">{{ i.u }}</small>
      </b-carousel-slide>
    </b-carousel>
    <b-row class="mb-5 justify-content-center text-center">
      <b-col cols="*" md="10" lg="9" xl="8">
        <b-card body-class="p-3">
          <p class="lead">{{ $t('homec.ct') }}</p>
          <p class="text">{{ $t('homec.c1') }}</p>
          <p class="pt-3">
            <b-button variant="primary" class="btn-lg" to="/" role="button" id="lanRegRest">
              <b-icon-info-circle v-bind="iB" /> {{ $t('homec.cb') }}
            </b-button>
          </p>
        </b-card>
      </b-col>
    </b-row>
    <ModalSignupCustomer v-if="(!isLoggedIn||wasLoggedIn) && showSignup" @hook:mounted="modalLoad('sgnWindowC')" @click="showSignup=true" @submit="showSignup=false" @reset="showSignup=false" />
  </div>
</template>

<script>
import { BIconBoxArrowInRight, BIconCardChecklist, BIconInfoCircle, BIconCalendarCheck, BIconDisplay, BIconCreditCard, BIconShieldLock, BIconBinoculars, BIconTools } from 'bootstrap-vue';

export default {
  data() {
    return {
      res: 252123,
      loc: 2021,
      users: 66317,
      hovered: null,
      showSignup: false,
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.oLogin.isLoggedIn;
    },
    wasLoggedIn() {
      return this.$store.state.oLogin.wasLoggedIn;
    },
    userComments() {
      return [
        { h: this.$t('homec.oh1'), t: this.$t('homec.ot1'), u: this.$t('homec.ou1') },
        { h: this.$t('homec.oh2'), t: this.$t('homec.ot2'), u: this.$t('homec.ou2') },
        { h: this.$t('homec.oh3'), t: this.$t('homec.ot3'), u: this.$t('homec.ou3') },
      ];
    },
    advantages() {
      return [
        { h: this.$t('homec.ah1'), t: this.$t('homec.at1'), c: 'BIconCalendarCheck' },
        { h: this.$t('homec.ah2'), t: this.$t('homec.at2'), c: 'BIconDisplay' },
        { h: this.$t('homec.ah3'), t: this.$t('homec.at3'), c: 'BIconCreditCard' },
        { h: this.$t('homec.ah4'), t: this.$t('homec.at4'), c: 'BIconBinoculars' },
        { h: this.$t('homec.ah6'), t: this.$t('homec.at6'), c: 'BIconShieldLock' },
        { h: this.$t('homec.ah5'), t: this.$t('homec.at5'), c: 'BIconTools' },
      ];
    },
    exeSteps() {
      return [
        { t: this.$t('homec.s1'), i: ()=>require('@/assets/devices.jpg'), a: this.$t('homec.s1a')},
        { t: this.$t('homec.s2'), i: ()=>require('@/assets/confirmation.jpg'), a: this.$t('homec.s2a')},
        { t: this.$t('homec.s3'), i: ()=>require('@/assets/qrregister.jpg'), a: this.$t('homec.s3a')},
        { t: this.$t('homec.s4'), i: ()=>require('@/assets/phone.jpg'), a: this.$t('homec.s4a')},
      ];
    },
    regSteps() {
      return [
        { t: this.$t('homec.u1') },
        { t: this.$t('homec.u2') },
        { t: this.$t('homec.u3') },
        { t: this.$t('homec.u4') },
      ];
    },
    iT() {
      return this.$store.getters.iT;
    },
    iB() {
      return this.$store.getters.iB;
    },
  },
  methods: {
    fNum(v) {
      return this.$n(v, 'd');
    },
    modalLoad(name) {
      this.$bvModal.show(name); // fix for lazyloading modals
    },
    onHover(val) {
      this.hovered = val;
    },
    onUnhover() {
      this.hovered = null;
    },
  },
  components: {
    ModalSignupCustomer: () => import('@/components/navigation/ModalSignupCustomer'),
    BIconBoxArrowInRight,
    BIconCardChecklist,
    BIconInfoCircle,
    BIconCalendarCheck,
    BIconDisplay,
    BIconCreditCard,
    BIconBinoculars,
    BIconShieldLock,
    BIconTools,
  }
}
</script>
